#Main {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &#Header {
      background-color: var(--gray-4);
      color: white;
      z-index: 2;
      position: sticky;
      top: 0;
    }

    &#Content {
      flex: 1;
    }

    &#Footer {
      background-color: var(--accent-12);
      color: white;
      // z-index: 2;
      // position: sticky;
      // bottom: 0;
    }
  }
}
