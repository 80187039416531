@import "./buttons.scss";
@import "./toasts.scss";

$inputBackgroundColor: var(--accent-a2);
$radioBackgroundColor: var(--accent-a3);

.radix-themes {
  .rt-TextFieldInput:where(.rt-variant-soft) + :where(.rt-TextFieldChrome) {
    background-color: $inputBackgroundColor;
  }

  .rt-SelectTrigger:where(.rt-variant-soft) {
    background-color: $inputBackgroundColor;
  }

  :is(.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput))
    + :where(.rt-TextAreaChrome) {
    background-color: $inputBackgroundColor;
  }

  .rt-RadioGroupRoot:where(.rt-variant-soft) :where(.rt-RadioGroupButton) {
    background-color: $radioBackgroundColor;
  }
  .rt-CheckboxButton:where(.rt-variant-soft) {
    background-color: $radioBackgroundColor;
  }
}
