@import "./radix-ui/_index.scss";

body {
  margin: 0;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.not-clickable {
  &:hover {
    cursor: not-allowed;
  }
}

button {
  &:hover {
    cursor: pointer;
  }
}

@media print {
  .printer-margins {
    margin: 25mm;
  }
}
